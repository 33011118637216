import React, { Component } from "react";

class CertificateParticles extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      require("particles.js/particles");
      const particlesJS = window.particlesJS;
      particlesJS.load("particles-js", "/certificate-particles.json", function() {});
    }
  }

  render() {
    return (
      <div>
        <div id="particles-js" />
        <style>
          {`
          #particles-js {
            position:absolute;
            z-index:0;
            height: 100vh;
            width: 100vw;
         }

          `}
        </style>
      </div>
    );
  }
}

export default CertificateParticles;
