import React, { useState, useEffect } from "react";
// import Helmet from "react-helmet";

import { Row, Col } from "react-grid-system";
import CertificateParticles from "components/Cohort/CertificateParticles";
import {
  getIBuiltThis,
  fetchUserData,
  getCustomIBuiltThis,
  getCohortDate,
  checkUserDataForCohort
} from "api/db";

import {
  Container,
  IBuiltThisButton,
  Verify
} from "components/Cohort/CertificateStyles";
import { navigate } from "gatsby";

const Certificate = ({ location }) => {
  const [IBuiltThis, setIBuiltThis] = useState(null);
  const [customIBuiltThis, setCustomIBuiltThis] = useState(null);
  const [name, setName] = useState(null);
  const [username, setUserName] = useState(null);
  const [cohort, setCohort] = useState("");
  const [cohortDate, setCohortDate] = useState("");
  const [numProjects, setNumProjects] = useState(4);

  const noCohort = () => {
    alert("This user has not completed a cohort");
    navigate("/404");
  };

  useEffect(() => {
    let user = location.pathname.slice(20);
    fetchUserData(user).once("value", snapshot => {
      let userData = snapshot.val();
      let cohort = "";
      if (userData) {
        cohort = checkUserDataForCohort(Object.values(userData)[0]);
      }
      if (userData && cohort && cohort != "") {
        setCohort(cohort);
        getCohortDate(cohort).once("value", snapshot => {
          setCohortDate(snapshot.val());
        });
        let key = Object.keys(userData)[0];
        getIBuiltThis(key).once("value", function(snapshot) {
          if (snapshot.val()) {
            let list = Object.values(snapshot.val());
            list = list.filter(
              IBuiltThis => IBuiltThis.tags && IBuiltThis.tags.includes(cohort)
            ); // make this cohort tag dyanamic from userData
            if(list.length < 3) {
              setNumProjects(list.length);
            }
            if (cohort == "cohort 1" || cohort == "cohort 0") {
              setIBuiltThis(list);
              setName(Object.values(userData)[0].username);
              setUserName(Object.values(userData)[0].id);
            } else if (list.length >= 3) {
              getCustomIBuiltThis(key).once("value", snapshot => {
                if (snapshot.val()) {
                  let customList = Object.values(snapshot.val());
                  customList = customList.filter(
                    custIBuiltThis =>
                      custIBuiltThis.tags &&
                      custIBuiltThis.tags.includes(cohort)
                  );
                  if (customList.length >= 1) {
                    setIBuiltThis(list);
                    setName(Object.values(userData)[0].username);
                    setUserName(Object.values(userData)[0].id);
                    setCustomIBuiltThis(customList[0]);
                  } else {
                    noCohort();
                  }
                } else {
                  noCohort();
                }
              });
            } else {
              noCohort();
            }
          } else {
            noCohort();
          }
        });
      } else {
        noCohort();
      }
    });
  }, []);
  return (
    <>
      <CertificateParticles />
      <Container>
        <span>
          <h3>{cohortDate}</h3>
        </span>
        <span>THIS CERTIFICATE VERIFIES THAT</span>
        <h1>{name}</h1>
        <p className="cohortDescription">
          {`has completed Enlight ${cohort.replace(/^c/, "C")}, having built ${numProjects} projects in web
          development and data science.`}
        </p>
        <h3>
          <span>PROJECTS</span>
        </h3>
        <Row className="row">
          {IBuiltThis &&
            IBuiltThis.map((project, index) => {
              return (
                index < 2 && (
                  <Col>
                    {/* <img src={project.img} height="200px"/> */}
                    <h3>{project.title}</h3>
                    <p style={{ marginBottom: "8px" }}>
                      {project.description && `${project.description}`}
                    </p>
                    {project.github ? (
                      <>
                        <p className="link" style={{ marginBottom: "4px" }}>
                          Source:{" "}
                          <a href={`${project.github}`}>{project.github}</a>
                        </p>
                        <IBuiltThisButton
                          className="button"
                          bg="#d25299"
                          href={project.github}
                        >
                          Source
                        </IBuiltThisButton>
                      </>
                    ) : null}
                    {project.demo ? (
                      <>
                        <p className="link" style={{ marginBottom: "4px" }}>
                          Demo: <a href={`${project.demo}`}>{project.demo}</a>
                        </p>
                        <IBuiltThisButton
                          className="button"
                          bg="#b93cf6"
                          href={project.demo}
                        >
                          Demo
                        </IBuiltThisButton>
                      </>
                    ) : null}
                  </Col>
                )
              );
            })}
        </Row>
        <Row className="row">
          {IBuiltThis &&
            IBuiltThis.map((project, index) => {
              return (
                index >= 2 &&
                index < ((cohort == "cohort 1" || cohort == "cohort 0") ? 4 : 3) && (
                  <Col>
                    {/* <img src={project.img} height="200px"/> */}
                    <h3>{project.title}</h3>
                    <p style={{ marginBottom: "8px" }}>
                      {project.description && `${project.description}`}
                    </p>
                    {project.github ? (
                      <>
                        <p className="link" style={{ marginBottom: "4px" }}>
                          Source:{" "}
                          <a href={`${project.github}`}>{project.github}</a>
                        </p>
                        <IBuiltThisButton
                          className="button"
                          bg="#d25299"
                          href={project.github}
                        >
                          Source
                        </IBuiltThisButton>
                      </>
                    ) : null}
                    {project.demo ? (
                      <>
                        <p className="link" style={{ marginBottom: "4px" }}>
                          Demo: <a href={`${project.demo}`}>{project.demo}</a>
                        </p>
                        <IBuiltThisButton
                          className="button"
                          bg="#b93cf6"
                          href={project.demo}
                        >
                          Demo
                        </IBuiltThisButton>
                      </>
                    ) : null}
                  </Col>
                )
              );
            })}
          {customIBuiltThis && (
            <Col>
              {/* <img src={project.img} height="200px"/> */}
              <h3>{customIBuiltThis.title}</h3>
              <p style={{ marginBottom: "8px" }}>
                {customIBuiltThis.description &&
                  `${customIBuiltThis.description}`}
              </p>
              {customIBuiltThis.github ? (
                <>
                  <p className="link" style={{ marginBottom: "4px" }}>
                    Source:{" "}
                    <a href={`${customIBuiltThis.github}`}>
                      {customIBuiltThis.github}
                    </a>
                  </p>
                  <IBuiltThisButton
                    className="button"
                    bg="#d25299"
                    href={customIBuiltThis.github}
                  >
                    Source
                  </IBuiltThisButton>
                </>
              ) : null}
              {customIBuiltThis.demo ? (
                <>
                  <p className="link" style={{ marginBottom: "4px" }}>
                    Demo:{" "}
                    <a href={`${customIBuiltThis.demo}`}>
                      {customIBuiltThis.demo}
                    </a>
                  </p>
                  <IBuiltThisButton
                    className="button"
                    bg="#b93cf6"
                    href={customIBuiltThis.demo}
                  >
                    Demo
                  </IBuiltThisButton>
                </>
              ) : null}
            </Col>
          )}
        </Row>

        <img src="/img/ribbon.png" id="ribbon" />

        <Verify>
          Verify at https://enlight.nyc/cohort/certificate/{username} <br />
          Enlight has confirmed their participation in this course.
        </Verify>
      </Container>
    </>
  );
};

export default Certificate;
